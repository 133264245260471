body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* /////////////////////////////////////////////////
 */
 ul{ 
  padding: auto;
  background-color: rgb(16, 16, 90);
 }
 ul li{
  list-style: none;
  display: inline-block;
  padding: .5rem 1rem;
  font-size: 1.5rem;
  font-weight: 600;

 }
 li .link{
  text-decoration: none;
  color: aliceblue;
 }
.btn{
  background-color: blueviolet;
  color: aliceblue;
  padding: 0.5rem 1rem;
  text-decoration: none;
  cursor: pointer;
  border-radius: 1rem;

}